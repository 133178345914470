.some {
  margin: 100px 0;
}

.some .work {
  font-weight: 600;
  font-size: 26px;
  line-height: 180%;
  color: #7c1034;
  margin-bottom: 90px;
}

.some .project {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px;
}

.some .project .box {
  max-width: 300px;
  position: relative;
  overflow: hidden;
  padding: 25px 10px;

  box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.25);
}

.some .project .box img {
  width: 100%;
}

.some .project .box .shadow1 {
  max-width: 300px;
  width: 100%;
  height: 50%;

  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.768) 99.04%,
    rgba(0, 0, 0, 0.568) 92.17%
  );
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  position: absolute;
  bottom: -100%;
  left: 0;
  z-index: 99;
}

.some .project .box:hover .shadow1 {
  top: 50%;
  transition: top 2s ease;
  color: white;
  font-weight: 600;
  font-size: 12px;
  padding: 15px;
}

.some .project .box:hover .shadow1 p:first-child {
  font-weight: 900;
  font-size: 14px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1000px) {
  .some .project {
    gap: 30px;
  }
}

@media only screen and (max-width: 800px) {
  .some .project {
    grid-template-columns: repeat(2, 1fr);
  }

  .some .project .box:hover .shadow1 {
    padding: 5px;
  }
}

@media only screen and (max-width: 600px) {
  .some .project {
    gap: 15px;
  }

  .some .project .box:hover .shadow1 p:first-child {
    display: none;
  }

  .some .project .box .shadow1 {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
