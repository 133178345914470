.homebody {
  /* height: calc(100vh - 140px); */
  /* background-color: rgba(0, 0, 0, 0.55); */
  background-image: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.55)),
    url(../../images/home/Rectangle\ 3.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.homebody .width {
  display: flex;
  height: 100%;
}

.homebody .width .talk {
  width: 50%;
  height: 100%;
}

.homebody .width .talk:nth-child(2) {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.homebody .width .talk .nigeria {
  margin-top: 170px;
  font-weight: 900;
  font-size: 20px;
  line-height: 73px;
  color: #faab9a;
}

.homebody .width .talk .nigeria p:last-child {
  font-size: 40px;
  line-height: 73px;
  /* letter-spacing: 0.13em; */
  color: #ffffff;
}

@media only screen and (max-width: 1200px) {
  .homebody .width .talk .nigeria p:last-child {
    font-size: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .homebody .width .talk .nigeria {
    font-size: 18px;
    line-height: 180%;
  }

  .homebody .width .talk .nigeria p:last-child {
    font-size: 24px;
    margin-top: 30px;
    line-height: 180%;
  }
}

@media only screen and (max-width: 840px) {
  .homebody .width {
    flex-direction: column;
  }
  .homebody .width .talk:nth-child(2) {
    margin-left: 0;
    justify-content: center;
  }
  .homebody .width .talk {
    width: 100%;
  }

  .homebody .width .talk .nigeria {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .homebody .width .talk .nigeria {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 400px) {
  .homebody .width .talk .nigeria {
    font-size: 16px;
  }

  .homebody .width .talk .nigeria p:last-child {
    font-size: 18px;
  }
}
