.implement {
  margin-top: 90px;
}

.implement .talk {
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
  margin-bottom: 60px;
}

.implement .talk p:first-child {
  font-weight: 700;
  font-size: 34px;
  color: #a91746;
  margin-bottom: 50px;
  line-height: 180%;
}

.implement .usercenter {
  display: flex;
  gap: 30px;
  color: white;
  margin-bottom: 100px;
}

.implement .usercenter.reverse {
  flex-direction: row-reverse;
}

.implement .usercenter .pictalk:nth-child(2) {
  /* background: #c11a50; */
  color: black;
  border-radius: 20px;
  padding: 30px 40px;
}

.implement .usercenter .pictalk {
  font-weight: 400;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.1em;
  width: 50%;
}

.implement .usercenter .pictalk:first-child {
  display: flex;
  align-items: center;
}

.implement .usercenter .pictalk img {
  width: 100%;
}

.implement .usercenter .pictalk p:first-child {
  font-weight: 700;
  font-size: 24px;
  line-height: 180%;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1000px) {
  .implement .usercenter,
  .implement .usercenter.reverse {
    flex-direction: column;
    width: 100%;
  }

  .implement .usercenter .pictalk {
    width: 100%;
  }

  .implement .usercenter .pictalk:first-child {
    max-width: 500px;
    margin: auto;
  }
}

@media only screen and (max-width: 600px) {
  .implement .talk {
    font-size: 16px;
    line-height: 140%;
  }
  .implement .talk p:first-child {
    font-size: 24px;
    margin-bottom: 30px;
  }

  .implement .usercenter .pictalk:last-child {
    padding: 0;
  }

  .implement .usercenter .pictalk p:first-child {
    font-size: 18px;
  }

  .implement .usercenter .pictalk {
    line-height: 140%;
    letter-spacing: normal;
  }

  .implement .usercenter {
    margin-bottom: 30px;
  }
}
