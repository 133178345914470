.shadow {
  max-width: 800px;
  background-color: white;
  margin: auto;
  text-align: center;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.25);
  margin-top: -50px;
  padding: 40px;

  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.175em;
}

.shadow img {
  width: 100%;
  margin-top: 30px;
}

/* .shadow  {
  display: flex;
  flex-direction: column;
} */

@media only screen and (max-width: 900px) {
  .shadow {
    margin-top: 30px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    padding: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .shadow {
    margin-top: 30px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    padding: 30px;
    font-size: 14px;
  }
}
