.sb {
  margin-top: 80px;
}

.sb p {
  font-weight: 700;
  font-size: 26px;
  /* letter-spacing: 0.175em; */
  text-align: center;
  margin-bottom: 70px;
  line-height: 180%;
}

.sb img {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .sb p {
    font-size: 14px;
    margin-bottom: 30px;
  }
}
