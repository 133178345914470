.contact .width h2 {
  font-weight: 400;
  font-size: 20px;
  line-height: 83px;
  letter-spacing: 0.175em;
  margin: 60px 0;
}

.contact form {
  /* background: #fee3dc; */
  background: #fbf8f6;
  padding: 100px 50px;
  margin-bottom: 50px;
}

.contact form p {
  font-weight: 700;
  font-size: 24px;
  line-height: 82px;
  letter-spacing: 0.09em;
  margin-bottom: 20px;
}

.contact hr {
  background: rgba(16, 16, 16, 0.5);
  margin-bottom: 30px;
}

.contact label {
  font-weight: 400;
  font-size: 24px;
  line-height: 82px;
  letter-spacing: 0.09em;
  color: rgba(0, 0, 0, 0.8);
}

.contact input,
.contact textarea {
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 20px;
  outline: none;
  font-size: 16px;
}

.contact input {
  height: 67px;
  margin-bottom: 30px;
}

.contact form button {
  margin-left: 0;
  margin-top: 40px;
}

@media only screen and (max-width: 650px) {
  .contact form p {
    font-size: 24px;
    line-height: 180%;
  }

  .contact .width h2 {
    font-size: 16px;
    margin: 30px 0;
  }

  .contact form {
    padding: 30px;
  }

  .contact label {
    font-size: 16px;
  }

  .contact input {
    margin-bottom: 0;
  }

  .contact .width h2 {
    line-height: 180%;
  }
}
