.about .homebody .width .talk .nigeria {
  margin-top: 0px;
  padding: 80px 0 200px;
}

a {
  text-decoration: none;
  color: inherit;
}

.about .homebody {
  background-image: linear-gradient(rgba(0, 0, 0, 0.79), rgba(0, 0, 0, 0.79)),
    url("../../images/about/Rectangle\ 3\ \(1\).png");
  min-height: 70vh;
}

.about .testimonial {
  /* background: #fee3dc; */
}

@media only screen and (max-width: 850px) {
  .about .homebody .width {
    min-height: 70vh;
    justify-content: center;
  }

  .about .homebody .width .talk:nth-child(2) {
    display: none;
  }

  .about .homebody {
    background-image: linear-gradient(rgba(0, 0, 0, 0.79), rgba(0, 0, 0, 0.79)),
      url("../../images/about/Rectangle\ 3\ \(2\).png");
  }
}
