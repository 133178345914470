.other {
  /* background-color: #fee3dc; */
  background: #fbf8f6;
  padding: 50px 0;
}

.other .talk p:first-child {
  font-weight: 600;
  font-size: 26px;
  line-height: 83px;
  letter-spacing: 0.175em;
  color: #7c1034;
}

.other .talk p:nth-child(2) {
  font-weight: 700;
  font-size: 36px;
  line-height: 66px;
  letter-spacing: 0.175em;
  color: #000000;
  margin: 30px 0;
}

.other .talk p:nth-child(2) span {
  color: #7c1034;
}

.other .talk p:nth-child(3) {
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.125em;
}

.other .gridtry {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin: 100px 0;
}

.other .gridtry .box img {
  max-width: 100px;
}

.other .gridtry .box img.h {
  max-height: 130px;
}

.other .gridtry .box {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.125em;
  cursor: pointer;
  color: #000000;
  transition: 0.2s ease all;
}

.other .gridtry .box:hover {
  transform: scale(1.05);
}

.other .gridtry .box p:first-of-type {
  margin: 20px 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 180%;

  letter-spacing: 0.125em;
  color: #000000;
}

@media only screen and (max-width: 1000px) {
  .other .gridtry {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin: 100px 0;
  }
}

@media only screen and (max-width: 800px) {
  .other .talk p:first-child {
    font-size: 14px;
    line-height: normal;
  }

  .other .talk p:nth-child(2) {
    font-size: 24px;
    line-height: 180%;
    letter-spacing: normal;
  }

  .other .talk p:nth-child(3) {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: normal;
  }

  .other .gridtry {
    margin: 50px 0;
  }

  .other .gridtry .box {
    letter-spacing: normal;
    line-height: 140%;
  }

  .other .gridtry .box img {
    width: 100%;
    max-width: 150px;
  }

  .other .gridtry .box p:first-of-type {
    letter-spacing: normal;
    line-height: 180%;
    margin: 10px 0;
    font-size: 18px;
  }
}

@media only screen and (max-width: 600px) {
  .other .gridtry {
    gap: 15px;
  }
}
