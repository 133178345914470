.footer {
  background: #121212;
  color: white;
  padding: 60px 0;
}

.footer .width {
  display: flex;
  justify-content: space-between;
}

.footer .four:first-child img {
  width: 200px;
  margin-bottom: 30px;
}

.footer .four h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 20px;
}

.footer .four ul {
  margin-left: 20px;
}

.footer .four ul li {
  margin-bottom: 30px;
  cursor: pointer;
}

.footer .four .social img {
  width: 30px;
  margin-right: 30px;
  cursor: pointer;
}

.footer .four:first-child {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
}

.footer .four:first-child p {
  font-weight: 600;
  font-size: 18px;
  /* letter-spacing: 0.05em; */
}

.footer .c {
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  margin-top: 50px;
}

@media only screen and (max-width: 1000px) {
  .footer .width {
    flex-direction: column;
  }

  .footer .four {
    margin-bottom: 30px;
  }

  .footer .four:first-child p {
    display: none;
  }
}

@media only screen and (max-width: 350px) {
  .footer .four:first-child img {
    width: 150px;
  }
}
