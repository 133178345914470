.leading .width {
  margin: 200px auto;
  display: flex;
  gap: 40px;
}

.leading button {
  margin: 0;
}

.leading .width .talk {
  width: 50%;

  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.125em;
}

.leading .width .talk p:first-child {
  font-weight: 700;
  font-size: 36px;
  line-height: 180%;
  letter-spacing: 0.175em;
  margin-bottom: 40px;
  max-width: 400px;
}

.leading .width .talk img {
  width: 100%;
}

.leading .width .talk button {
  margin-top: 30px;
}

@media only screen and (max-width: 1000px) {
  .leading .width {
    flex-direction: column;
    gap: 50px;
  }

  .leading .width .talk {
    width: 100%;
    /* text-align: left; */
  }

  .leading .width .talk p:first-child {
    max-width: 100%;
  }

  .leading .width .talk img {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .leading .width {
    margin: 0;
  }

  .leading .width .talk p:first-child {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .leading .width .talk {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: normal;
  }
}
