.help {
  padding: 80px 0;
  background: #7c1034;
}

.help button {
  margin: 0;
  background: white;
  color: #c11a50;
}

.help button:hover {
  background: #c11a50;
  color: white;
}

.help .width {
  display: flex;
}

.help .two {
  width: 50%;
}

.help .two img {
  width: 900px;
  margin-top: -550px;
}

.help .two {
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.09em;
  color: #ffffff;
}

.help .two p:nth-child(2) {
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.085em;
  margin-top: 20px;
  margin-bottom: 50px;
}

@media only screen and (max-width: 600px) {
  .help .two {
    font-size: 18px;
    font-weight: 700;
    width: 100%;
  }

  .help .two p:nth-child(2) {
    margin-bottom: 30px;
    font-weight: 500;
  }

  .help .width {
    flex-direction: column;
  }
}
