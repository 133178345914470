body {
  margin: 0;
  font-family: "malgun-gothic-regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection {
  color: #fee3dc;
  background: #7c1034;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* line-height: 180%; */
}

div {
  /* border: 2px solid black; */
}
