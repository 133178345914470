.website .talk .design {
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  letter-spacing: 0.175em;
  color: #ffffff;
}

.website .talk .design p:first-child {
  font-weight: 700;
  font-size: 40px;
  line-height: 73px;
  letter-spacing: 0.13em;
  color: #ffffff;
  margin-top: 54px;
  margin-bottom: 22px;
}

.website .design ul {
  list-style: none;
  margin-top: 50px;
  margin-bottom: 50px;
}

.website .design ul li {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1000px) {
  .website .talk .design {
    font-size: 16px;
    line-height: 140%;
    font-weight: 400;
    letter-spacing: normal;
  }

  .website .talk .design p:first-child {
    font-size: 24px;
    line-height: 180%;
    letter-spacing: normal;
    max-width: 300px;
  }

  .website .design ul {
    margin: 50px 0 0;
  }

  .website .design ul li {
    gap: 15px;
  }
}
