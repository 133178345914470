.grow {
  background-color: black;
  max-width: 600px;
  padding: 30px 50px;
  border-radius: 20px;
  margin: 75px 0;
}

.grow p {
  color: white;
  text-align: center;
  font-weight: 900;
  font-size: 28px;
}

.grow p:nth-child(2) {
  font-weight: 400;
  font-size: 18px;
  /* letter-spacing: 0.175em; */
  margin-top: 15px;
  margin-bottom: 25px;
}

.grow input,
.grow select {
  max-width: 500px;
  width: 100%;
  background: #ffffff;
  border-radius: 5px;
  font-weight: 500;
  font-size: 15px;
  padding: 20px;
  outline: none;
  border: none;
  margin-bottom: 20px;
  color: black;
}

.grow .option {
  font-weight: 500;
  font-size: 15px;
  padding: 20px;
}

.grow button {
  padding: 20px 90px;
}

.grow div {
  text-align: center;
  margin-top: 10px;
}

@media only screen and (max-width: 1000px) {
  .grow p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .grow {
    padding: 30px 20px;
    margin: 40px 0;
  }

  .grow p {
    font-size: 18px;
  }

  .grow p:nth-child(2) {
    font-size: 16px;
  }

  .grow button {
    padding: 20px 40px;
  }
}
