.enter {
  margin-top: 300px;
  margin-bottom: 100px;
  overflow: hidden;
}

.enter .pictalk {
  display: flex;
  align-items: center;
}

.enter.reverse .pictalk {
  flex-direction: row-reverse;
}

/* .enter.reverse .pictalk .pic {
  position: relative;
} */

.enter.reverse .pictalk .talk {
  position: absolute;
}

.enter.reverse .pictalk .talk .content {
  /* margin-left: 0;
  margin-right: -350px; */
}

.enter .pictalk .pic {
  /* width: 50%;
  min-width: 650px;
  height: 850px; */
}

.enter .pictalk .pic img {
  /* width: 100%; */
}

.enter .pictalk .talk {
  width: 50%;
}

.enter .pictalk .talk .content {
  /* background: #fee3dc; */
  background: #fbf8f6;

  margin-left: -300px;
  width: 630px;
  padding: 70px 40px;
  /* height: 700px; */

  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
}

.enter .pictalk .talk p:first-child {
  font-weight: 700;
  font-size: 36px;
  line-height: 66px;
  letter-spacing: 0.175em;
  padding-bottom: 40px;
}

.enter .pictalk .talk button {
  margin-top: 40px;
}

@media only screen and (max-width: 1500px) {
  .enter .pictalk .talk .content {
    /* margin-left: -500px; */
  }

  .enter.reverse .pictalk .talk .content {
    margin-left: -500px;
  }
}

@media only screen and (max-width: 1200px) {
  .enter .pictalk .talk .content {
    margin-left: -450px;
  }

  .enter.reverse .pictalk .talk .content {
    margin-left: -400px;
  }
}

@media only screen and (max-width: 1000px) {
  .enter .pictalk {
    flex-direction: column-reverse;
  }

  .enter .pictalk .talk {
    width: 100%;
  }

  .enter .pictalk .talk .content {
    margin-left: 0;
    width: 100%;
    margin-bottom: 50px;
  }

  .enter.reverse .pictalk .talk {
    position: static;
  }

  .enter.reverse .pictalk {
    flex-direction: column-reverse;
  }

  .enter.reverse .pictalk .talk .content {
    margin-left: 0;
  }
}

@media only screen and (max-width: 800px) {
  .enter .pictalk .pic img {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .enter .pictalk .talk .content {
    font-size: 16px;
    line-height: 140%;
    padding: 70px 20px;
  }

  .enter .pictalk .talk p:first-child {
    font-size: 18px;
    line-height: 180%;
  }

  .enter {
    margin-top: 50px;
  }
}
