.pattern {
  background-image: url("./images/Group\ 194.png");
  background-size: contain;
  height: 24px;
}

.pattern2 {
  background-image: url(./images/Rectangle\ 104.png);
  background-size: contain;
  height: 40px;
  opacity: 0.1;
}

@media only screen and (max-width: 600px) {
  button {
    padding: 20px 40px;
  }

  .pattern2 {
    background-size: cover;
  }
}
