.long {
  /* height: 1000px; */
  padding: 50px 0;
  margin-top: 120px;
  background-image: url(../../images/home/Rectangle\ 7.png);
}

.long button {
  margin: 0;
}

.long .width {
  display: flex;
}
.long .talk {
  width: 50%;
}

.long .talk p:first-child {
  font-weight: 600;
  font-size: 26px;
  line-height: 83px;
  /* letter-spacing: 0.175em; */
  color: #7c1034;
}

.long .talk p:nth-child(2) {
  max-width: 530px;
  font-weight: 700;
  font-size: 34px;
  line-height: 66px;
  /* letter-spacing: 0.175em; */
  margin-bottom: 25px;
  margin-top: 20px;
}

.long .talk p:nth-child(2) span {
  color: #7c1034;
}

.long .talk p:nth-child(3) {
  font-weight: 400;
  font-size: 16px;
  line-height: 35px;
  margin-bottom: 20px;
  /* letter-spacing: 0.125em; */
  color: #000000;
}

.long .talk p:nth-child(4) {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 30px;
  margin-left: 50px;
  line-height: 180%;
}

.long .talk p:nth-child(4) span {
  color: #7c1034;
}

.long .talk img {
  width: 90%;
  margin-left: 50px;
}

.number {
  padding: 100px 0;
}

.number p:first-child {
  font-weight: 700;
  font-size: 26px;
  line-height: 180%;
  /* letter-spacing: 0.175em; */
  color: #000000;
  text-align: center;
}

.number .speak {
  display: flex;
  justify-content: space-between;
  /* margin-top: 60px; */
  max-width: 700px;
  margin: 60px auto 0;
  line-height: 140%;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
}

.number .speak p:first-child {
  margin-bottom: 15px;
  color: #7c1034;
  font-weight: 700;
  font-size: 48px;
  /* letter-spacing: 0.175em; */
}

.why {
  /* background: #fee3dc; */
  /* background: #f7f7f7; */
  background: #fbf8f6;
  padding: 100px 0;
}

.why .result {
  display: flex;
  flex-wrap: wrap;
  gap: 50px;
  justify-content: center;
  margin-top: 100px;
}

.why .result .driven {
  width: 100%;
  max-width: 500px;
  display: flex;
}

.why .result .icon {
  /* width: 100px; */
  margin-right: 30px;
}

.why .result .icon img {
  width: 70px;
}

.why .result .talk {
  font-weight: 400;
  font-size: 16px;
  line-height: 140% !important;
  /* letter-spacing: 0.125em; */
  color: #000000;
}

.why .result .talk p:first-child {
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  /* letter-spacing: 0.175em; */
  margin-bottom: 10px;
}

.why p.g {
  font-weight: 700;
  font-size: 26px;
  line-height: 83px;
  /* letter-spacing: 0.175em; */
  color: #000000;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .long .talk:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .why .result {
    gap: 30px;
  }

  .why .result .driven {
    max-width: 400px;
  }
}

@media only screen and (max-width: 1000px) {
  .long .talk p:first-child {
    font-size: 14px;
  }

  .long .talk p:nth-child(2) {
    font-size: 18px;
    line-height: 180%;
  }

  .long .talk p:nth-child(3) {
    font-size: 16px;
    line-height: 180%;
  }
  .long .talk p:nth-child(4) {
    font-size: 16px;
    margin-left: 20px;
  }

  .why .result .driven {
    max-width: 300px;
  }
}

@media only screen and (max-width: 900px) {
  .long .width {
    flex-direction: column;
  }

  .long .talk {
    width: 100%;
  }

  .long .talk:last-child {
    margin-top: 50px;
  }

  .long .talk img {
    margin-left: 0;
  }

  .number p:first-child {
    font-size: 18px;
  }

  .number .speak {
    font-size: 14px;
    margin-top: 30px;
    text-align: center;
  }

  .number .speak p:first-child {
    font-size: 18px;
  }
}

@media only screen and (max-width: 700px) {
  .why .result .driven {
    max-width: 100%;
  }

  .why .result {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .long {
    margin-top: 40px;
  }
  .number {
    padding: 50px 0;
  }

  .why .result .icon img {
    width: 50px;
  }

  .why .result .icon {
    margin-right: 20px;
  }

  .why p.g {
    font-size: 18px;
  }

  .why {
    padding: 50px 0;
  }
}
