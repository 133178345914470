.header {
  background-color: black;
}

.width {
  max-width: 1400px;
  margin: auto;
  padding: 0 70px;
}

.header .width {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .logo {
  max-width: 200px;
  padding: 40px 0;
}

.header .logo img {
  width: 100%;
}

.header .talk {
  color: white;
}

.header .talk ul {
  display: flex;
  align-items: center;
  text-decoration: none;
  list-style: none;
  gap: 30px;
}

.header .talk ul li {
  color: white;
  cursor: pointer;
  padding: 40px 0;
  font-weight: 700;
}

.header .talk ul li:hover {
  transition: 0.5s ease all;
  color: #c11a50;
}

button {
  background: #c11a50;
  border-radius: 10px;
  width: 300px;
  margin: auto;
  height: 70px;
  outline: none;
  color: white;
  line-height: 25px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

button img {
  display: none;
  width: 25px;
}

.header button {
  width: 200px;
  height: 70px;
}

button:hover img {
  display: flex;
  width: 25px !important;
  height: 25px !important;
  margin: 0 !important;
}

h5 {
  font-size: 15px;
  font-weight: 500;
}

button:hover h5 {
  margin-right: 10px;
  transition: all 0.2s ease-in;
  /* font-size: 16px; */
}

.header button:hover {
  /* padding: 20px 25px; */
  /* transition: all 0.5s ease-in; */
}

a button:hover,
button:hover {
  background: #92133e;
  transition: 0.2s all ease-in-out;
}

.header .menu {
  display: none;
}

.header .mobile {
  display: none;
}

.header .active {
  background: white;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  min-height: 100vh;
  z-index: 99;
  overflow: scroll;
  max-height: 100%;
}

.header .products {
  padding: 10px 0;

  font-size: 16px;
  line-height: 152%;
  display: flex;
  align-items: center;
  margin: 0 20px;
  color: #121212;
  border-bottom: 0.3px solid #c6c6c6;
}

.header .noncon {
  height: 0;
  overflow: hidden;
}

.header .content {
  padding-top: 10px;
  margin: 0 20px;
}

.header .content .for {
  display: flex;
  margin-bottom: 22px;
}
.header .content .for a {
  display: flex;
  align-items: center;
}

.header .content .for .image {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.header .content .for .image img {
  width: 100%;
}

/* submenu  */

.sub-menu .this {
  max-width: 1300px;
  margin: auto;
  font-weight: 600;
}

.sub-menu .this:nth-child(2) {
  margin-top: 50px;
}

.header ul li:hover .sub-menu .this {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header ul li .sub-menu {
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: 0;
  width: 100%;
  /* background: #faab9a; */
  background: #fbf8f6;
  margin-top: 25px;
  color: black;

  line-height: 120%;
  font-size: 18px;
  border: none;
  z-index: 900;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transform: translateX(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.05);
}

.header ul li:hover .sub-menu {
  height: 179px;
  padding-top: 40px;
  transform: translateX(0);
  opacity: 1;
}

.sub-menu .four {
  display: flex;
  align-items: center;
  padding: 23px 15px;
  width: 260px;
}

.sub-menu .four a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-menu .four:hover {
  background: #fee3dc;
  border-radius: 8px;
}

.sub-menu .four:hover .good {
  background: #fee3dc;
}

.sub-menu .four:first-child {
  font-size: 23px;
  line-height: 180%;
  margin-right: 130px;
  width: 280px;
}

.sub-menu .four.hide {
  visibility: hidden;
}

.sub-menu.dev .four:first-child {
  width: 350px;
  margin-right: 80px;
}

.sub-menu.com .four:first-child {
  width: 350px;
}

.sub-menu .four .good {
  max-width: 53px;
  margin-right: 25px;
  padding: 15px;
  background: white;
  border-radius: 4px;
}

.sub-menu .four .good img {
  width: 100%;
}

.sub-menu2 {
  width: 100%;
  position: absolute;
  left: 0;
  top: 155px;
  background: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: bold;
  font-family: "variable-bold";
  font-size: 18px;
  line-height: 140%;

  color: #000000;
  border: none;
  margin-top: 0px;
  z-index: 900;
  height: 0;
  opacity: 0.5;
  overflow: hidden;
  transform: translateY(-10px);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.05);
}

.sub-menu .four:hover .sub-menu2 {
  padding: 40px 0;
  height: auto;
  opacity: 1;
  transform: translateY(0px);
}

.sub-menu .four:hover .sub-menu2 .that {
  display: flex;
}

.sub-menu2 .that {
  width: 1440px;
  margin: auto;
}

.sub-menu2 .consume {
  margin-right: 30px;
  margin-top: 40px;
}

.sub-menu2 hr {
  position: absolute;
  width: 80%;
  top: 50px;
  border: 1px solid #dddddd;
}

@media only screen and (max-width: 1200px) {
  .sub-menu.dev .four:first-child {
    margin-right: 20px;
    width: 250px;
  }

  .sub-menu.dev .four.devv {
    width: 270px;
  }
}

@media only screen and (max-width: 1000px) {
  .header .talk {
    display: none;
  }

  .header .menu {
    display: flex;
    width: 30px;
    height: 30px;
  }

  .header .width {
    background-color: black;
    /* margin-bottom: 30px; */
  }

  .header .products.ggg {
    margin-top: 30px;
  }

  .header .products:last-child {
    border-bottom: 0px solid #000;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 840px) {
  .width {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 600px) {
  .header .logo {
    max-width: 130px;
  }

  button {
    width: 200px;
    height: 50px;
  }
}
