.testimonial {
  /* background: #fcbdae; */
  background-blend-mode: darken;
  padding: 80px 0;
  padding-bottom: 200px;
}

.homepage .testimonial {
  display: none;
}

.testimonial .long {
  margin-top: 0;
  padding: 0;
  background: none;
  width: 100%;
}

.testimonial .talk {
  width: 100%;
}

.testimonial .team {
  display: flex;
  gap: 50px;
  justify-content: space-between;
  margin-top: 80px;
}

.testimonial .team .box {
  max-width: 400px;
  width: 100%;
  background: #ffffff;
  border-radius: 10px;
}

.testimonial .team .box:hover {
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.25);
  transform: scale(1.05);
  transition: all 0.2s ease;
}

.testimonial .team .box img {
  width: 100%;
}

.testimonial .team .box .ok {
  padding: 45px 23px;

  font-weight: 700;
  font-size: 24px;
  line-height: 180%;
  color: #000000;
}

.testimonial .team .box .ok p:first-child {
  font-weight: 400;
  font-size: 20px;
}

.testimonial .team .box .ok .o {
  display: flex;
  gap: 30px;
  margin-top: 30px;
}

.testimonial .team .box .ok .solid {
  width: 10px;
  height: 50px;
  background: #faab9a;
  border-radius: 10px;
}

.testimonial .team .box .ok img {
  max-width: 36px;
}

@media only screen and (max-width: 1200px) {
  .testimonial .long .talk:last-child {
    flex-direction: column;
    align-items: start;
  }

  .testimonial .team {
    gap: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  .testimonial .team {
    flex-direction: column;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .testimonial .team .box .ok .o {
    gap: 10px;
  }

  .testimonial .team .box .ok {
    font-size: 16px;
  }

  .testimonial .team .box .ok p:first-child {
    font-size: 14px;
  }

  .testimonial .team .box .ok img {
    width: 20px;
  }

  .testimonial .team .box .ok .o {
    gap: 15px;
  }
}

@media only screen and (max-width: 300px) {
  button {
    width: 100%;
  }
}
