.ourservice {
  margin: 100px 0;
}

.ourservice h1 {
  font-weight: 600;
  font-size: 26px;
  /* letter-spacing: 0.175em; */
  color: #7c1034;
  margin-bottom: 90px;
}

.ourservice .web {
  display: flex;
  gap: 30px;
  justify-content: center;
  text-align: center;

  font-weight: 400;
  font-size: 16px;
  text-align: center;
  /* letter-spacing: 0.1em; */
}

.ourservice .web .design .talk {
  margin-bottom: 30px;
}

.ourservice .web .design .talk p:first-child {
  font-weight: 700;
  font-size: 18px;
  /* letter-spacing: 0.175em; */
  height: 50px;
  width: 250px;
  margin: auto;
  text-decoration: underline;
  margin-bottom: 20px;
}

.ourservice .web .design .icon img {
  max-width: 150px;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1000px) {
  .ourservice .web {
    flex-direction: column;
  }

  .ourservice .web .design .talk p:first-child {
    width: 100%;
    height: auto;
  }

  .ourservice h1 {
    margin-bottom: 50px;
  }

  .ourservice .web {
    gap: 50px;
  }
}

@media only screen and (max-width: 600px) {
  .ourservice .web .design .icon img {
    max-width: 100px;
    margin-bottom: 15px;
  }
}
