.mission {
  /* background: #fee3dc; */
  background: #fbf8f6;
  padding: 70px 0;
}

.mission .width {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.mission .talk {
  width: 50%;

  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  letter-spacing: 0.125em;
}

.mission .talk p:first-child {
  font-weight: 700;
  font-size: 36px;
  line-height: 66px;
  letter-spacing: 0.175em;
  margin-bottom: 25px;
}

@media only screen and (max-width: 900px) {
  .mission {
    margin-top: 50px;
  }
  .mission .width {
    flex-direction: column;
  }

  .mission .talk {
    width: 100%;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .mission .talk {
    line-height: 140%;
    letter-spacing: normal;
    font-size: 16px;
  }

  .mission .talk p:first-child {
    line-height: 180%;
    font-size: 18px;
    margin-bottom: 15px;
  }
}
